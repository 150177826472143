import AsyncComputed from 'vue-async-computed'
import Vuex from 'vuex'
import { rootPath } from '@i18n/setup'

import BasedQuery from '@api/based/basedQuery'

import textToFile from '@shared/helpers/text-to-file.js'
import { staging, production, env, local, legacyOldUrl , appBaseUrl} from '@shared/helpers/env-helpers.js'
import { i18n } from '@i18n/setup'

import Vue from 'vue'

import _snakeCase from 'lodash/snakeCase'

import dayjs from 'dayjs'
import FlagIcon from 'vue-flag-icon'
import VueClipboard from 'vue-clipboard2'

import api from '@api'
import helpers from './loaders/helpers.js'
import htmlToPng from '@shared/helpers/html-to-png.js'
import htmlToPdf from '@shared/helpers/html-to-pdf.js'

const Helpers = {
  install(Vue, options) {
    Vue.mixin({
      created: function () {},
      computed: {
        $inIframe() {
          try {
            return window.self !== window.top
          } catch (e) {
            return true
          }
        },
      },
      methods: {
        notifyError(message) {
          this.$store.dispatch('notifyError', { message: message })
        },
        translate(key, params) {
          return this.$i18n.t(
            `${rootPath ? rootPath + '.' : ''}components.${
              this.translateKey || this.$options.name
                ? (this.translateKey || this.$options.name) + '.'
                : ''
            }${key}`,
            params
          )
        },
        async $resolve(query, querySubName = null) {
          const queryName = (querySubName ? `${this.$options.name}.${querySubName}` : this.$options.name)
          return (await query.resolve(queryName))
        }
      },
    })

    dayjs.extend(require('dayjs/plugin/quarterOfYear'))
    Vue.prototype.$date = dayjs
    Vue.prototype.$api = api
    Vue.prototype.$localMonitorApi = api.localMonitor

    Vue.prototype.$helpers = helpers

    Vue.filter('round2', function (value) {
      if (value == null || value == 'undefined') return null

      return value.toFixed(2) == parseInt(value)
        ? parseInt(value)
        : value.toFixed(2)
    })

    Vue.filter('round1', function (value) {
      if (value == null || value == 'undefined') return null

      return parseFloat(value.toFixed(1)) == parseFloat(value.toFixed(0))
        ? value.toFixed(0)
        : value.toFixed(1)
    })

    Vue.filter('round', function (value) {
      if (value == null || value == 'undefined') return null

      return Math.round(value)
    })

    Vue.filter('toPercent', function(value, precision = 0) {
      return Number((value * 100).toFixed(precision))
    })

    Vue.filter('fromPercent', function(value, precision = 2) {
      return Number((value / 100).toFixed(precision))
    })

    Vue.filter('ordinal', function (number) {
      const ordinals = i18n.t(`number.ordinals`, i18n.locale)

      if (number > ordinals.length) return ordinals[ordinals.length - 1]

      return ordinals[number - 1]
    })

    Vue.filter('prettyHours', function (hours) {
      if (["-", null].includes(hours)) return "-"

      return helpers.dates.humanizeDurationShort(hours)
    })

    Vue.filter('numberWithSpace', function numberWithSpace(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    })

    Vue.filter('truncate20', function truncate(str) {
      const max = 20
      return (str.length <= max ? str : str.slice(0, max) + '...')
    })

    Vue.filter('truncate30', function truncate(str) {
      const max = 30
      return (str.length <= max ? str : str.slice(0, max) + '...')
    })

    Vue.filter('day', function week(date) {
      if (date) {
        return helpers.dates.day(date)
      }
    })

    Vue.filter('week', function week(date) {
      if (date) {
        return helpers.dates.week(date)
      }
    })

    Vue.filter('month', function month(date) {
      if (date) {
        return helpers.dates.month(date)
      }
    })

    Vue.filter('quarterYear', function quarter(date) {
      if (date) {
        return helpers.dates.quarterYear(date)
      }
    })

    Vue.filter('year', function quarter(date) {
      if (date) {
        return helpers.dates.year(date)
      }
    })

    Vue.filter('capitalize', function capitalize(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    })

    Vue.filter('ddmmyyyy', function ddmmyyyy(date) {
      return helpers.dates.ddmmyyyy(date)
    })

    Vue.prototype.$rules = {
      required: function (v) {
        return !!v || ''
      },
      arrayRequired: function (v) {
        return (!!v && v.length > 0) || ''
      },
      email: function (v) {
        return (
          !v || /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(v) || ''
        )
      },
    }

    Vue.prototype.$required = function (v) {
      return !!v || ''
    }

    Vue.prototype.$basedRequest = function () {
      return new BasedQuery({ scope: 'user' })
    }

    Vue.prototype.$adminBasedRequest = function () {
      return new BasedQuery({ scope: 'admin' })
    }

    Vue.prototype.$textToFile = function (fileName, fileContent, options = {}) {
      return textToFile(fileName, fileContent, options)
    }

    Vue.prototype.$htmlToPng = function(element, filename, html2CanvasOptions) {
      return htmlToPng(element, filename, html2CanvasOptions)
    }

    Vue.prototype.$htmlToPdf = async function(element, filename, html2CanvasOptions) {
      return await htmlToPdf(element, filename, html2CanvasOptions)
    }

    Vue.prototype.$randomId = function (fileName, fileContent) {
      return Math.random().toString(36).substr(2, 9)
    }

    Vue.prototype.$key = function (...args) {
      return args.reduce((key, arg) => {
        return `${key}_${arg}`
      }, "")
    }

    Vue.prototype.$unaccent = function (str) {
     return str?.trim()?.normalize('NFD')?.replace(/\p{Diacritic}/gu, '')
    }

    Vue.prototype.$sortByAlpha = function (arr, attr) {
      return arr.sort((a, b) =>
        a[attr]?.localeCompare(b[attr])
      )
    }

    Vue.prototype.$open = function (url, target = "_self") {
      window.location.href = `${url}${url.includes("?") ? "&" : "?"}href=${target}`
    } 

    Vue.prototype.$staging = staging
    Vue.prototype.$production = production
    Vue.prototype.$local = local
    Vue.prototype.$env = env
    Vue.prototype.$legacyOldUrl = legacyOldUrl
    Vue.prototype.$appBaseUrl = appBaseUrl

    Vue.prototype.$LOADING = 'loading'
  },
}

Vue.use(Helpers)
Vue.use(AsyncComputed)
Vue.use(Vuex)
Vue.use(FlagIcon)
Vue.use(VueClipboard)
Vue.use(require('vue-moment')) // obsolete, will need to be removed when finished migrating to Dayjs

export default Helpers
