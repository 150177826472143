<template>
  <RankingTableWidget
    :title="title"
    :customRankingRelatedIndicator="customRankingRelatedIndicator"
    :staticColumns="staticColumns"
    :campaign="campaign"
  />
</template>

<script>
  import { mapGetters } from 'vuex'
  import RankingTableWidget  from '@statistics/shared/widgets/RankingTableWidget'

  export default {
    name: "FeedbackTreatmentRankingTableWidget",
    props: [ 'campaign' ],
    components: {
      RankingTableWidget
    },
    computed: {
      ...mapGetters([
        'currentLexicon'
      ]),
      title() {
        return this.currentLexicon.translate('places', 'places') ? `${this.$t('competitors_widget_ranking_title')} ${this.currentLexicon.translate('places', 'places') } - ${this.$t('feedbackTreatmentRankingTableTitle')}` : `${this.$t('widget_place_ranking_title')} - ${this.$t('feedbackTreatmentRankingTableTitle')}`
      },
      customRankingRelatedIndicator() {
        return 'g_processing_time'
      },
      staticColumns() {
        return {
          g_processing_time: {},
          g_processed_insatisfactions: {},
          g_to_treat_time: {},
          g_unprocessed_insatisfactions: {},
          g_oldest_unprocessed_time: {},
          nbReview: {}
        }
      }
    }
  }
</script>
