<template>
  <div
    class="insatisfaction-archived"
  >
    <w-timeline-element
      right
    >
      <template v-slot:avatar>
        <CompanyAvatar/>
      </template>

      <template v-slot:title>
        <div class="d-flex">
          <span
            class="text-truncate fb-14 c-primary"
          >
            {{ currentUserBrandName }}
          </span>
          <v-tooltip top>
            <template #activator="{on}">
              <span class="fb-14 c-primary" v-on="on">{{ createdAt }}</span>
            </template>
            {{ createdAtTooltip }}
          </v-tooltip>
        </div>
      </template>

      <template v-slot:content>
        <div
          class="d-flex pa-4 rounded-lg bg-verylightgrey"
        >
          <div
            class="d-flex flex-column ga-3"
            :style="{ width: '100%' }"
          >
            <div class="d-flex align-center ga-1">
              <v-icon class="f-16 c-primary">mdi-account</v-icon>
              <span class="c-primary fb-14">
                {{ $t('insatisfaction_archived') }}
              </span>
            </div>
            <pre
              class="c-darkergrey f-14 content"
            >
             {{ insatisfactionArchived.comment }}
            </pre>

            <v-divider></v-divider>
            <div class="d-flex flex-row align-center internal-memo-footer">
              <div>
                <v-icon small>mdi-account</v-icon>
                <span class="f-12">
                  {{ insatisfactionArchivedFrom }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </w-timeline-element>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CompanyAvatar from '../../CompanyAvatar'

  export default {
    name: "InsatisfactionArchived",
    props: {
      insatisfactionArchived: { required: true },
      voter: { required: true }
    },
    components: {
      CompanyAvatar
    },
    data () {
      return {}
    },
    computed: {
      ...mapGetters([
        'currentUser',
        'currentVoterEditingCallReport',
        'currentUserBrandName',
        'currentVoterShowActions',
        'currentVoterActionsTab'
      ]),
      createdAt() {
        return this.$t('voter_timeline_element_created_at', { date: this.$helpers.dates.short(this.insatisfactionArchived.processedDate, false) })
      },
      createdAtTooltip() {
        return this.$helpers.dates.short(this.insatisfactionArchived.processedDate, true)
      },
      insatisfactionArchivedFrom() {
        return this.insatisfactionArchived.userEmail
      },
    },
  }
</script>

<style lang='stylus'>
  .insatisfaction-archived
    .v-badge .v-badge__wrapper
      margin-left: 0px
    .v-card__text
      padding-bottom: 6px !important

    .content
      white-space: pre-line;
</style>
