<template>
  <WStatsWidget
    ref="widget"
    :cols="cols"
    :footerSpacer="footerSpacer"
    :subtitle="defaultSubtitle"
    :title="title"
    :loading="isLoading"
    :empty="!displayAvgToTreatTime"
    :emptyLabel="$t('feedback_to_treat_time_widget_no_data')"
    class="feedback-to-treat-time-widget"
    height="200px"
  >
    <template #help-tooltip>
      <v-tooltip top transition="none">
        <template #activator="{ on }">
          <v-icon v-on="on" class="f-16 c-oslo-gray">
            mdi-information
          </v-icon>
        </template>
        <div class="d-flex flex-column" :style="{ width: '400px' }">
          <div class="fs-13">{{ $t('feedback_to_treat_time_widget_help_tooltip_main') }}</div>
          <div class="f-13">{{ $t('feedback_to_treat_time_widget_help_tooltip_secondary') }}</div>
        </div>
      </v-tooltip>
    </template>
    <template #content>
      <span class="d-flex flex-column align-center ga-1">
        <span><HumanizeDuration :duration="humanizeAvgToTreatTime" /></span>
        <span v-if="nbInsatisfactionToTreat" class="sub-text f-12 c-lightgrey">{{ translateNbReview(nbInsatisfactionToTreat) }}</span>
      </span>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import FeedbackTreatmentMixin from '@statistics/shared/feedback_treatment_mixin'
import HumanizeDuration from './HumanizeDuration'

export default {
  name: 'FeedbackToTreatTimeWidget',
  mixins: [
    WidgetMixin,
    FeedbackTreatmentMixin,
  ],
  components: {
    HumanizeDuration
  },
  props: {
    campaign: { type: Object },
    cols: { type: Number, default: 3 },
    footerSpacer: { type: Boolean, default: false },
  },
  data() {
    return {
      decimals: 0
    }
  },
  methods: {
    translateNbReview(nbReviews) {
      return this.$t('feedback_to_treat_time_widget_nb_review_to_treat', {
        nbReviews: nbReviews
      })
    }
  },
  computed: {
    ...mapGetters([
      'dashboardFilterReady',
      'isMonoPlace',
      'networkRankingName',
      'networkRankingPlaceIds',
      'sqlDateEnd',
      'placeIds',
    ]),
    isLoading() {
      return this.avgToTreatTime === undefined
    },
    title() {
      return this.$t('feedback_to_treat_time_widget_title')
    },
    networkRanking() {
      return this.rankFor(this.networkDatas, this.indicatorToTreatTimeAlias)
    },
    displayAvgToTreatTime() {
      if (this.dashboardFilterReady && this.avgToTreatTime)
        return this.avgToTreatTime[this.indicatorToTreatTimeAlias] != null
    },
    humanizeAvgToTreatTime() {
      return this.avgToTreatTime[this.indicatorToTreatTimeAlias]
    },
  },
  asyncComputed: {
    avgToTreatTime: {
      lazy: true,
      default: undefined,
      async get() {
        if (this.dashboardFilterReady) {
          const request = this.avgToTreatTimeRequest()

          return (await this.$resolve(request)).first()
        }
      },
    },
    nbInsatisfactionToTreat: {
      lazy: true,
      default: undefined,
      async get() {
        if (this.dashboardFilterReady) {
          const request = this.nbInsatisfactionToTreatRequest()

          return (await this.$resolve(request)).first()?.nbInsatisfactionToTreat || 0
        }
      },
    },
    networkDatas: {
      async get() {
        if (this.dashboardFilterReady && this.isMonoPlace && this.networkRankingPlaceIds) {

          const request = this.avgToTreatTimeRequest().where({
            "place_campaigns_place_id": this.networkRankingPlaceIds
          }).group([
            "place_campaigns_place_id"
          ]).order([this.avgToTreatTimeColumns(), "asc"])

          const networkRankingDatas = await this.$resolve(request)

          return networkRankingDatas.data.insatisfactionToTreatTimes
        }
      },
      default: null,
    },
  }
}
</script>
