import _chunk from 'lodash/chunk'

async function parralelFor(concurrence, array, callback) {
  const arrayChunks = _chunk(array, concurrence)

  let chunkResults = []

  for (let i = 0; i < arrayChunks.length; i++) {
    const chunkResult = await Promise.all(arrayChunks[i].map((arrayItem, arrayItemIdx) => callback(arrayItem, i * concurrence + arrayItemIdx, array)))

    if (chunkResult.includes(false)) {
      break
    }

    chunkResults.push(...chunkResult)
  }
  return chunkResults
}

export {
  parralelFor
}