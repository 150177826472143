<template>
  <EvolutionWidget
    :title="title"
    :basedRequest="basedRequest"
    :campaign="campaign"
    :indicatorAlias="indicatorAlias"
    :colorLines="['#5656BF', '#0EDDAB']"
    :legendEnabled="true"
    :yAxisLabelsFormatter="yAxisLabelsFormatter"
    :decimals="1"
    :min="0"
    :chartZoom="null"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import FeedbackTreatmentMixin from '@statistics/shared/feedback_treatment_mixin'
import EvolutionWidget from '@statistics/shared/widgets/EvolutionWidget'
import { buildTimeSeriesDates } from '@api/based/basedHelper'

export default {
  name: "FeedbackTreatmentEvolutionWidget",
  components: {
    EvolutionWidget
  },
  mixins: [
    WidgetMixin,
    FeedbackTreatmentMixin
  ],
  props: {
    campaign: { required: true },
  },
  data() {
    return {
      loading: true,
      insatisfactionGraphIndicatorOptions: [
        { text: "insatisfactionProcessingGraph", value: 'insatisfactionProcessingGraph' },
        { text: "insatisfactionToTreatGraph", value: 'insatisfactionToTreatGraph' }
      ],
      insatisfactionGraphIndicator: 'insatisfactionProcessingGraph',
      indicatorAlias: 'timeseries'
    }
  },
  methods: {
    formatTimeSeriesData(timeSeriesData) {
      return timeSeriesData.map((data) => {
        let timeseries = (
          data && data.timeseries !== null ?
            data.timeseries / 24 :
            null
        )
        return { timeseries }
      })
    },
    yAxisLabelsFormatter(yValue) {
      if (yValue === 0) {
        return `0 ${this.$t("date.day")}`
      } else {
        return `${yValue} ${this.$t("date.days")}`
      }
    },
  },
  computed: {
    title() {
      return `${this.$t('widget_evolution_title')} - ${this.$t('feedback_treatment_widget_title')} & ${this.$t('feedback_to_treat_time_widget_title')}`
    },
    basedRequest() {
      const uniqueId = Math.random().toString(36).substr(2, 9)
      const self = this
      const dayjs = require('dayjs')

      const requestObj = {
        id: uniqueId,
        dateBegin: null,
        dateEnd: null,
        groups: null,
        dateBetween(dateBegin, dateEnd) {
          this.dateBegin = dateBegin
          this.dateEnd = dateEnd
          return this
        },
        group(groups) {
          this.groups = groups
          return this
        },
        async resolve(
          queryName,
          {
            tickNumber,
            tickUnit,
            timeSerieParams
          },
          endPoint
        ) {
          let avgProcessingTimeRequest = self.avgProcessingTimeRequest({
            aliasName: self.indicatorAlias
          }).group(
            this.groups
          ).dateBetween(this.dateBegin, this.dateEnd)

          let avgProcessingTimeData = (await avgProcessingTimeRequest.resolve(
            "FeedbackTreatmentEvolutionWidget",
            {
              tickNumber: tickNumber,
              tickUnit: tickUnit,
              timeSerieParams: timeSerieParams
            },
            "time_series"
          ))?.data || {}

          if (avgProcessingTimeData.series[0]) {
            avgProcessingTimeData.series[0].data = self.formatTimeSeriesData(avgProcessingTimeData.series[0].data)
            avgProcessingTimeData.series[0].name = self.$t('feedback_treatment_widget_title')
          } else {
            avgProcessingTimeData.series = [{ data: [], name: self.$t('feedback_treatment_widget_title'), visible: true }]
          }

          let avgToTreatTimeDataSeries = { data: [], name: self.$t('feedback_to_treat_time_widget_title'), visible: false }

          let dateForQuery = buildTimeSeriesDates(this.dateBegin, this.dateEnd, tickUnit)

          let avgToTreatTimeQueryResult = await self.$helpers.parralel.parralelFor(4, dateForQuery, async (date, i) => {
            const avgToTreatTime = self.avgToTreatTimeRequest({
              aliasName: self.indicatorAlias,
              dateEnd: date.dateEnd
            })
            return (await self.$resolve(avgToTreatTime))?.first()
          })

          avgToTreatTimeDataSeries.data = self.formatTimeSeriesData(avgToTreatTimeQueryResult)


          let data = {
            labels: avgProcessingTimeData.labels,
            series: [
              avgToTreatTimeDataSeries,
              avgProcessingTimeData.series[0]
            ]}

          return {
            data: data || {}
          }
        }
      }
      return Object.create(requestObj)
    }
  },
}
</script>
