<template>
  <div>
    <span v-if="humanizeDurationArray">
      <span v-if="primaryHumanizeDuration">
        <span class="c-primary fs-25">
          {{ primaryHumanizeDuration.unitQuantity }}
        </span>
        <span class="c-primary f-20">
          {{ primaryHumanizeDuration.unitName }}
        </span>
      </span>
      <span v-if="secondaryHumanizeDuration">
        <span class="c-primary f-20">
          {{ $t('time_and') }}
        </span>
        <span class="c-primary fs-25">
          {{ secondaryHumanizeDuration.unitQuantity }}
        </span>
        <span class="c-primary f-20">
          {{ secondaryHumanizeDuration.unitName }}
        </span>
      </span>
    </span>
    <span v-else>
      {{ $t('no_data_text') }}
    </span>
  </div>  
</template>

<script>

export default {
  name: 'HumanizeDuration',
  props: ['duration'],
  computed: {
    humanizeDurationArray() {
      return this.$helpers.dates.humanizeDuration(this.duration, true)
    },
    primaryHumanizeDuration() {
      return this.humanizeDurationArray[0]
    },
    secondaryHumanizeDuration() {
      return this.humanizeDurationArray[1]
    }
  },
}
</script>
