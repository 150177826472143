<template>
  <WLateralSectionLayout class="pl-3 feedback-treatment-zoom">
    <template #lateral-section>
      <FeedbackProcessingTimeWidget :campaign="campaign" :cols="12"/>
      <FeedbackToTreatTimeWidget :campaign="campaign" :cols="12"/>
      <FeedbackTreatmentRankingWidget :campaign="campaign" :cols="12" v-if="isMonoPlace"/>
    </template>

    <template #main-section>
      <FeedbackTreatmentEvolutionWidget :campaign="campaign" />
      <FeedbackTreatmentRankingTableWidget :campaign="campaign" />
    </template>
  </WLateralSectionLayout>
</template>

<script>
  import { mapGetters } from 'vuex'
  import FeedbackProcessingTimeWidget from './FeedbackProcessingTimeWidget'
  import FeedbackToTreatTimeWidget from './FeedbackToTreatTimeWidget'
  import FeedbackTreatmentRankingWidget from './FeedbackTreatmentRankingWidget'
  import FeedbackTreatmentEvolutionWidget from './FeedbackTreatmentEvolutionWidget'
  import FeedbackTreatmentRankingTableWidget from './FeedbackTreatmentRankingTableWidget'

  export default {
    name: "FeedbackTreatmentZoom",
    props: {
      campaign: { required: true },
    },
    components: {
      FeedbackProcessingTimeWidget,
      FeedbackToTreatTimeWidget,
      FeedbackTreatmentRankingWidget,
      FeedbackTreatmentEvolutionWidget,
      FeedbackTreatmentRankingTableWidget
    },
    computed: {
      ...mapGetters([
        'isMonoPlace'
      ])
    }
  }
</script>
