<template>
  <div class="ranking-table-group">
    <RankingTable
      :headers="headers"
      :items="formatedItems"
      :loading="loading"
      :scope="displayedScope"
      itemRankedColumn="groupName"
      @scopeChanged="updateSelectedScope"
      :campaign="campaign"
      :avgScoreScale="scoreScale"
      :rankingRelatedColumn="rankingRelatedColumn"
    />
  </div>
</template>

<script>
  import RankingTable from './RankingTable'
  import RankingTableMixin from "./RankingTable/ranking_table_mixin.js"

  import _concat from "lodash/concat"

  export default {
    name: "RankingTableGroup",
    components: {
      RankingTable
    },
    props: {
      rankById: {
        required: true
      },
      scope: {
        type: String,
        required: true
      },
      campaign: {},
      avgScoreScale: { type: Object, required: false }
    },
    mixins: [
      RankingTableMixin
    ],
    data() {
      return {
        customTableColumns: { based: 'custom_table_ranking_columns', key: 'customTableRankingColumns' },
        conditions: [
          { "group_semantic": this.rankById }
        ],
        ignoredColumnNames: [
          'g_place_name',
          'g_oldest_processing_time'
        ],
        aggregation: {
          baseColumn: "groupName",
          key: 'groupId',
          basedColumn: {  brand_voters: { 'group_id': { as: 'groupId' } } }
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
