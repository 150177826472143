import dayjs from 'dayjs'
import helpers from '../../plugins/loaders/helpers.js'

export function buildTimeSeriesDates(firstDate, lastDate, tickUnit) {
  let datesList = []
  firstDate = dayjs(firstDate)
  lastDate = dayjs.min(dayjs(lastDate), dayjs())

  while (firstDate.isBefore(lastDate)) {
    let dateBegin = firstDate
    let dateEnd = firstDate.add(1, tickUnit)

    let dateBeginFormatted = dayjs(dateBegin).format('YYYY-MM-DD')
    let dateEndFormatted = dayjs(dateEnd).format('YYYY-MM-DD')
    
    let prettyDate = getPrettyDate(dateBegin, tickUnit)
    if (dateEnd.isAfter(lastDate)) {
      datesList.push({ dateBegin: dateBeginFormatted, dateEnd: lastDate.format('YYYY-MM-DD'), prettyDate});
    } else {
      datesList.push({ dateBegin: dateBeginFormatted, dateEnd: dateEndFormatted, prettyDate });
    }

    firstDate = dateEnd
  }

  return datesList
}

export function getPrettyDate(date, tickUnit) {
  if (tickUnit === 'day') {
    return helpers.dates.day(date)
  } else if (tickUnit === 'week') {
    return helpers.dates.week(date)
  } else if (tickUnit === 'month') {
    return helpers.dates.month(date)
  } else if (tickUnit === 'quarter') {
    return helpers.dates.quarterYear(date)
  } else if (tickUnit === 'year') {
    return helpers.dates.year(date)
  }
}
