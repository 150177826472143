<template>
  <WStatsWidget
    ref="widget"
    :title="title"
    :height="height"
    :subtitle="displayedSubtitle"
    :loading="!isLoaded"
    :empty="isEmpty"
  >
    <template #content>
      <v-tooltip :bottom="true" color="white" content-class="count-grouped-by-source-tooltip-custom">
        <template v-slot:activator="{ on, attrs }">
          <div 
            class="count-grouped-by-source-div-global-content"
            v-bind="attrs"
            v-on="on"
            >
            <div class="count-grouped-by-source-div-chart">
              <Chart :options="options">
              </Chart>
            </div>
            <div class="d-flex flex-column flex-row align-center count-grouped-by-source-div-middle-chart">
              <span :class="`c-primary lh-34 ${getFontSize} font-weight-bold`">
                {{ new Intl.NumberFormat().format(nbReview) }}
              </span>
              <w-color-sign
                v-if="showEvolution"
                :decimals="0"
                :value="evolution"
                :suffix="' %'"
              />
            </div>
          </div>
        </template>
        <div>
          <table>
            <tbody>
              <tr
                v-for="countBySource in countsBySourceLegend"
                :key="countBySource[0]"
              >
                <td>
                  <v-avatar
                    :color="countBySource.color"
                    size="16"
                  ></v-avatar>
                  </td>
                <td style="padding: 0 25px">
                  <div class="d-flex align-center ga-1">
                    <w-source-icon
                      v-if="isWizvilleSource(countBySource.source)"
                      class="c-grey"
                      source="normal"
                      iconSize="f-14"
                      :autoColor="false"
                    />
                    <span class="c-primary lh-28 f-14 font-weight-bold">{{ countBySource.label }}</span>
                  </div> 
                </td>
                <td><span class="c-primary lh-28 f-14">{{ countBySource.nbReview }}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-tooltip>
    </template>
  </WStatsWidget>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chart } from "highcharts-vue"
import WidgetMixin from '@statistics/shared/widgets/widget_mixin'
import WizIcon from '@theme/components/WIcon'

const COLORSBYSOURCE = {
  'avisVerifies': '#6DBBB6', // Neptune
  'bazaarVoice': '#8BBB99', // Bay Leaf
  'deliveroo': '#9FBAB8', // Shadow Green
  'facebook': '#488BD4', // Danube
  'yotpo': '#C86868', // Chetnut Rose
  'mailing': '#B224EF', // 
  'mono': '#E6DF8D', // Flax
  'normal': '#B37C5A', // Antique Brass
  'pagesJaunes': '#FFD333', // Bright Sun 500
  'pollster': '#CEF2F0', // Sea Green 300
  'pull_web': '#9A8C5A', // Barley
  'push_web': '#9AA25F', // Green Smoke
  'qrcode': '#D3AB60', // Harvest Gold
  'skeepers': '#7F4E88', // Wisteria
  'sms': '#5363B0', // Indigo
  'smsPull': '#66ACDF', // Jordy Blue
  'tripAdvisor': '#AE6B84', // Bouquet
  'ubereats': '#A6BB50', // Celery 500
  'url': '#95AD86', // Grey Chateau
  'web': '#4C97A4', // Eastern Blue
  'google': '#7579FF', // 
}

const SOURCEMAPPING = {
  'pull_web': 'web',
  'push_web': 'web',
  'sms_pull': 'sms',
  'null': 'normal'
}

export default {
  name: 'CountGroupedBySourceWidget',
  components: { 
    Chart,
    WizIcon
   },
  mixins: [
    WidgetMixin
  ],
  data(){
    return{
      loaded: false,
      options: null,
      nbReview: 0,
      nbReviewPreviousPeriod: 0,
      countsBySource: []
    }
  },
  asyncComputed: {
    chartOptions: {
      async get() {
        this.loaded = false
        this.nbPrevious = 0;
        this.nbPreviousPeriod = 0;
        this.countsBySource = []

        let datas = [];
        let colors = [];
        
        const response = await this.$resolve(this.basedRequest)
        const responsePreviousPeriod = await this.$resolve(this.basedRequest.dateBetween(this.sqlComparedDateBegin, this.sqlComparedDateEnd))
        
        for (const [key, countBySource] of Object.entries(response.data['voters'])) {
          let groupedSource = SOURCEMAPPING[countBySource['source']] || countBySource['source']
          let notFound = true

          for (let i = 0; i < this.countsBySource.length; i++){
            if (this.countsBySource[i][0] == groupedSource) {
              this.countsBySource[i][1] = this.countsBySource[i][1] + countBySource['countDistinctId']
              notFound = false
            }
          }
          
          if (notFound) {
            this.countsBySource.push([groupedSource,countBySource['countDistinctId']])
          }
          this.nbReview = this.nbReview + countBySource['countDistinctId']
        }
        
        for (let i= 0; i < this.countsBySource.length; i++) {
          datas.push(this.countsBySource[i][1]);
          colors.push(this.colorBySource(this.countsBySource[i][0]));
        }

        for (const [key, countBySource] of Object.entries(responsePreviousPeriod.data['voters'])) {
          this.nbReviewPreviousPeriod = this.nbReviewPreviousPeriod + countBySource['countDistinctId']
        }

        let options = {
          chart: {
            type: 'pie',
            height: '140px',
            width: '160'
          },
          xAxis: {
            categories: []
          },
          plotOptions: {
            series: {
              thickness: 7,
              enableMouseTracking: false,
              borderRadius: '50%',
              borderWidth: 2,
              borderColor: 'white',
              dataLabels: {
                  enabled: false
              },
              size: '90%',
              innerSize: '70%',
            }
          },
          exporting: {
            enabled: false
          },
          colors: colors,
          series: [
            {
              data: datas
            }
          ],
          title: {
            text: '',
            align: 'center',
            verticalAlign: 'middle'
          },
          credits: { 
            enabled: false
          }
        }
        
        this.options = options
        this.loaded = true
        
        return true
      },
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'hasAccessToAllDashboardPlaces',
      'sqlComparedDateBegin',
      'sqlComparedDateEnd'
    ]),
    isLoaded() {
      return this.loaded
    },
    showEvolution() {
      return this.nbReviewPreviousPeriod !== null && this.nbReview !== null
    },
    evolution() {
      return this.nbReviewPreviousPeriod && this.nbReview ? Math.round(( this.nbReview - this.nbReviewPreviousPeriod ) / this.nbReview * 100 ) : null
    },
    isEmpty() {
      return this.nbReview == 0
    },
    getFontSize() {
      switch (true) {
        case this.nbReview < 100000:
          return "f-26"
        case this.nbReview < 1000000:
          return "f-22"
        case true:
          return "f-18"
      }
    },
    countsBySourceLegend() {
      let countsBySourceWizville = []
      let countsBySourceNotWizville = []

      for (let i = this.countsBySource.length - 1; i >= 0; i--) {
        const countBySource = {
          'source': this.countsBySource[i][0],
          'nbReview': this.countsBySource[i][1],
          'color': this.colorBySource(this.countsBySource[i][0]),
          'label': this.$t(`source.${this.countsBySource[i][0]}`)
        }
        if (this.isWizvilleSource(this.countsBySource[i][0])) {
          countsBySourceWizville.push(countBySource)
        } else {
          countsBySourceNotWizville.push(countBySource)
        }
      }
      return countsBySourceWizville.concat(countsBySourceNotWizville)
    },
    
  },
  methods: {
    colorBySource(source) {
      return COLORSBYSOURCE[source]
    },
    isWizvilleSource(source) {
      return !['google', 'facebook', 'pages_jaunes', 'trip_advisor', 'ubereats', 'deliveroo', 'skeepers'].includes(source)
    }
  },
  props: {
    basedRequest: { type: Object },
    title: { type: String, required: true },
    height: { type: String, default: '200px' },
  }
}
</script>

<style lang="stylus" scoped>
  .widget-link
    display: contents

  .strong
    font-weight: bold;

  .count-grouped-by-source-tooltip-custom
    border-radius: 5px;
    box-shadow: 2px 2px 6px lightgray;
    opacity: 1;

  .count-grouped-by-source-tooltip-custom::after
    content: ''
    position: absolute
    bottom: 100%
    left: 50%
    transform: translateX(-50%)
    width: 0
    height: 0
    border-left: 10px solid transparent
    border-right: 10px solid transparent
    border-bottom: 10px solid white

  .count-grouped-by-source-div-global-content
    position: relative;
    width: 160px;
    height: 140px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

  .count-grouped-by-source-div-chart
    top: 0px; 
    left: 0px; 
    position: absolute; 
    width: 160px; 
    height: 140px

  .count-grouped-by-source-div-middle-chart
    top: 45px;
    left: 0px;
    position: absolute;
    width: 160px;
</style>
