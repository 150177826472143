<template>
  <span v-if="showComponent" class="w-color-wrapper fs-12" :style="`color: ${properties.color}; ${customStyle}`">
    <span class="w-color-sign f-10" v-html="properties.sign"></span>
    <span v-if="!hideValue">{{ properties.content }}</span>
    <slot name="icon" :properties="properties">
    </slot>
  </span>
</template>

<script>
  const ARROW_DOWN = "&#x25bc;"
  const ARROW_UP = "&#x25b2;"

  const COLOR = "color"
  const SIGN = "sign"
  const CONTENT = "content"

  export default {
    name: "WColorSign",
    props: {
      value: { type: [Number, String], required: false, default: 0},
      hideValue: { type: Boolean, require: false, default: false },
      zero: { type: String, require: false, default: "=" },
      suffix: { type: String, require: false, default: "" },
      reverse: { type: Boolean, require: false, default: false },
      withColor: { type: Boolean, require: false, default: true },
      showZero: { type: Boolean, require: false, default: false },
      customStyle: { type: String, require: false, default: "" },
      decimals: { type: Number, require: false, default: 0 },
      color:  { type: String, require: false, default: "" }
    },
    data() {
      return {
        theme: this.$vuetify.theme
      }
    },
    computed: {
      numberValue() {
        return Number(this.value)
      },
      showComponent() {
        return this.numberValue > 0.0 || this.numberValue < 0.0 || this.showZero
      },
      properties: function() {
        const theme = this.theme.themes

        const red = 'var(--red)'
        const green = 'var(--gradient-green-start)'
        const black = 'black'

        let properties = {}
        if (this.numberValue > 0.0) {
          properties[COLOR] = this.reverse == true ? red : green
          properties[SIGN] = ARROW_UP
          properties[CONTENT] = `${this.numberValue.toFixed(this.decimals)}${this.suffix}`
        } else if (this.numberValue < 0.0) {
          properties[COLOR] = this.reverse == true ? green : red
          properties[SIGN] = ARROW_DOWN
          properties[CONTENT] = `${(this.numberValue * -1).toFixed(this.decimals)}${this.suffix}`
        } else {
          properties[COLOR] = 'lightblue'
          properties[SIGN] = ""
          properties[CONTENT] = `${this.zero}${this.showZero ? '' : this.suffix}`
        }
        if (!this.withColor) {
          properties[COLOR] = black
        }

        if (this.color !== "") {
          properties[COLOR] = this.color
        }

        if (properties[CONTENT]?.match(/\.0+$/)) {
          properties[CONTENT] = properties[CONTENT].split(".")[0]
        }
       
        return properties
      },
    },
  }
</script>

<style lang='stylus'>
  .w-color-wrapper
    display: inline-flex
    align-items: center

    .w-color-sign
      margin-right: 2px
</style>
