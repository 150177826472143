<template>
  <v-col
    :cols="12"
    :lg="cols"
    :md="cols < 6 ? 6 : 12"
    class="pr-3 pb-3 w-stats-widget"
  >
    <router-link
      class="widget-link"
      :is="linkTo ? 'router-link' : 'div'"
      :to="linkTo"
    >
      <v-card
        :id="widgetId"
        :style="{ height, minHeight, maxHeight }"
        :class="[
          'v-card',
          'd-flex',
          'flex-column',
          linkTo && !border ? 'clickable' : '',
        ]"
      >
        <div
          v-if="displayHeader"
          :id="widgetHeaderId"
          class="pa-3 w-stats-widget-header d-flex align-center"
        >
          <span class="la fs-14 w-stats-widget-header-picture">
            <v-chip
              v-if="$slots.headerPicture || headerEmoji || headerIcon"
              class="header-picture ma-2 bg-bodygrey"
              label
            >
              <slot name="headerPicture">
                <span v-if="headerEmoji">
                  {{ headerEmoji }}
                </span>
                <v-icon v-if="headerIcon">
                  {{ headerIcon }}
                </v-icon>
              </slot>
            </v-chip>
          </span>
          <div class="d-flex flex-column">
            <span class="fs-14 w-stats-widget-title c-primary">
              <slot name="title">
                {{ title }}
              </slot>
            </span>
            <span class="fs-10 lh-10 pt-1 c-grey">
              <slot name="subtitle">
                {{ subtitle }}
              </slot>
            </span>
          </div>
          <div class="flex-grow-1" />
          <v-icon v-if="linkTo" class="c-accent f-13">
            mdi-chevron-right
          </v-icon>
          <slot name="help-tooltip">
            <v-tooltip v-if="help" top transition="none">
              <template #activator="{ on }">
                <v-icon v-on="on" class="f-16 c-oslo-gray">
                  mdi-information
                </v-icon>
              </template>
              <span>
                {{ help }}
              </span>
            </v-tooltip>
          </slot>
          <div class="lh-14">
            <slot name="options" />
          </div>
        </div>

        <div
          @scroll="onContentScroll"
          :class="[
            'widget-content-container',
            'align-self-center',
            'd-flex',
            'flex-column',
            'align-center',
            'overflow-auto',
            justifyCenter ? 'justify-center' : '',
          ]"
          :style="{
            borderLeft: border ? `8px solid ${border}` : false,
            padding: contentPadding ? contentPadding : false,
          }"
        >
          <div v-if="!empty && !loading" :style="`width: ${contentWidth}`">
            <slot name="content" />
          </div>
          <div
            v-else-if="loading"
            class="f-14 full-width pa-3"
            :style="loadingHeight ? `height:${loadingHeight}` : null"
          >
            <v-skeleton-loader type="paragraph" />
          </div>
          <div
            v-else-if="empty"
            class="ma-auto f-13 pa-3 c-textgrey"
          >
            <div>{{ noDataLabel }}</div>
          </div>
        </div>

        <div
          :id="widgetFooterId"
          class="f-12 c-grey lh-30 bg-palegrey px-3 d-flex"
        >
          <!--
            We add `&nbsp;` as default content for the footer so the vertical centering in
            the widget is more consistent.
          -->
          <div>
            <slot name="footer-left">
              <div v-if="footerSpacer">&nbsp;</div>
            </slot>
          </div>
          <div class="flex-grow-1" />
          <div class="lh-30">
            <slot name="footer-right">
              <div v-if="footerSpacer">&nbsp;</div>
            </slot>
          </div>
        </div>
      </v-card>
    </router-link>
  </v-col>
</template>

<script>
  import _debounce from 'lodash/debounce'

  export default {
    name: 'WStatsWidget',
    props: {
      border: { type: String },
      cols: { type: Number, default: 3 },
      contentPadding: { type: String },
      contentWidth: { type: String, default: 'auto' },
      empty: { type: Boolean, default: false },
      emptyLabel: { type: String },
      footerSpacer: { type: Boolean, default: false },
      headerEmoji: { type: String },
      headerIcon: { type: String },
      height: { type: String, default: undefined },
      help: { type: String },
      justifyCenter: { type: Boolean, default: true },
      linkTo: {},
      loading: { type: Boolean, default: false },
      loadingHeight: { default: undefined },
      maxHeight: { type: String, default: undefined },
      minHeight: { type: String, default: undefined },
      subtitle: { type: String },
      title: { type: String },
    },
    data() {
      const randomId = this.$randomId()
      return {
        widgetId: "w-stats-widget-" + randomId,
        widgetHeaderId: "w-stats-widget-header-" + randomId,
        widgetFooterId: "w-stats-widget-footer-" + randomId,
      }
    },
    computed: {
      noDataLabel() {
        return this.emptyLabel || this.$t('no_data_label')
      },
      displayHeader() {
        return (!this.border && (this.title || this.subtitle))
      }
    },
    methods: {
      onContentScroll: _debounce(
        function(el) {
          this.$emit('onContentScroll', el)
          if (el.srcElement.offsetHeight + el.srcElement.scrollTop == el.srcElement.scrollHeight) {
            this.$emit('onContentScrollBottom', el)
          }
        },
        500
      )
    }
  }
</script>

<style lang="stylus" scoped>
  .w-stats-widget + .w-stats-widget
    padding-top: 0 !important
    padding-left: 0 !important

  .header-picture
    width: 32px
    height: 32px
    padding: 9px

  .widget-content-container
    -ms-overflow-style: none
    scrollbar-width: none
    width: 100%
    height: 100%

  .widget-content-container::-webkit-scrollbar
    display: none

  .w-stats-widget-header
    border-bottom: 1px solid var(--bodygrey)

  .v-card
    overflow: hidden

    &.clickable
      border: 1px solid var(--concrete)
      transition: border-color 350ms

      &:hover
        border-color: var(--accent)

  .mid
    position: relative
    bottom: 13px
</style>
