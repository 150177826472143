<template>
  <CountGroupedBySourceWidget
    :title="title"
    :basedRequest="basedRequest"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import CountGroupedBySourceWidget from '../../shared/widgets/CountGroupedBySourceWidget.vue'

export default {
  name: 'VoterCountGroupedBySourceWidget',
  components: {
    CountGroupedBySourceWidget
  },
  computed: {
    ...mapGetters([
      'dashboardFilterRequest'
    ]),
    basedRequest() {
      return this.dashboardFilterRequest.select({
        voters: [ "source", "COUNT_DISTINCT_id" ]
      }).group([
        "source"
      ]).order([
        "COUNT_DISTINCT_id", "asc"
      ])
    },
    title() {
      return this.$t(`voter_count_by_source`)
    }
  }
}
</script>
