<template>
  <div class="ranking-table-voter-info">
    <RankingTable
      :headers="headers"
      :items="formatedItems"
      :loading="loading"
      :scope="displayedScope"
      :itemRankedColumn="aggregation.baseColumn"
      @scopeChanged="updateSelectedScope"
      :campaign="campaign"
      :rankingRelatedColumn="rankingRelatedColumn"
    />
  </div>
</template>

<script>
  import RankingTable from './RankingTable'
  import RankingTableMixin from "./RankingTable/ranking_table_mixin.js"

  import _concat from "lodash/concat"

  export default {
    name: "RankingTableVoterInfo",
    components: {
      RankingTable
    },
    props: {
      rankById: {
        required: true
      },
      scope: {
        type: String,
        required: true
      },
      campaign: {},
      avgScoreScale: { type: Object, required: false }
    },
    mixins: [
      RankingTableMixin
    ],
    data() {
      return {
        customTableColumns: { based: 'custom_table_seller_ranking_columns', key: 'customTableSellerRankingColumns' },
        conditions: [
          { "voter_string_info_campaign_voter_key_string_info_id": this.rankById }
        ],
        ignoredColumnNames: [
          'g_place_name',
          'g_oldest_processing_time'
        ],
        aggregation: {
          baseColumn: 'voterStringInfoValue',
          key: 'voterStringInfoValue',
          basedColumn: { brand_voters: { 'voter_string_info_value': { as: 'voterStringInfoValue' } } }
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>

</style>
