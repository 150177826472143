<template>
  <div class="question-tree">
    <div class="question-title c-blue">
      <span class="f-13">
        {{ advancedContent }}
      </span>
    </div>

    <VImg
      :lazy-src="answer.product.image"
      max-height="90"
      max-width="60"
      :src="answer.product.image"
    />

    <VRating
      v-if="answer.markQuestionLabel?.score"
      class="w-rating"
      background-color="grey lighten-2"
      readonly
      :value="answer.markQuestionLabel.score"
      half-increments
      length="5"
      :size="$vuetify.breakpoint.xsOnly ? 12 : 16"
      :color="$colors.gold"
    />

    <div v-if="question.productsQuestionOptionsSet && answer.productQuestionAnswer" class="question-tree">
      <div v-if="answer.productQuestionAnswer.commentQuestionAnswer" class="question-filled-level-0">
        <div class="question-title c-blue">
          <span class="f-13">
            {{ question.productsQuestionOptionsSet.comment_question_content }}
          </span>
        </div>
        <div class="free-question-answer f-14">
          <WQuote :content="answer.productQuestionAnswer.commentQuestionAnswer" />
        </div>
      </div>
      <div v-if="answer.productQuestionAnswer.recommandQuestionAnswer" class="question-filled-level-0">
        <div class="question-title c-blue">
          <span class="f-13">
            {{ question.productsQuestionOptionsSet.recommand_question_content }}
          </span>
        </div>
        <div class="free-question-answer f-14">
          {{ upperFirstRecommandAnswer }}
        </div>
      </div>
    </div>

    <div
      v-if="answer.productQuestionAnswer"
      v-for="(productAnswer, idx) in answer.productQuestionAnswer.productSubQuestionAnswers"
      class="question-filled-level-0"
    >
      <div class="question-title c-blue mt-2">
        <span v-if="productAnswer.topic" class="fb-14 lh-15">{{ productAnswer.topic }} - </span>
        <span class="f-13">{{ productAnswer.questionContent }}</span>
      </div>
      <div v-if="productAnswer.questionDisplayType === 'scale'" class="free-question-answer f-14">
        {{ productAnswer.rateLabels[productAnswer.score] }}
      </div>
      <VRating
        v-else
        class="w-rating"
        background-color="grey lighten-2"
        readonly
        :value="productAnswer.score"
        half-increments
        length="5"
        :size="$vuetify.breakpoint.xsOnly ? 12 : 16"
        :color="$colors.gold"
      />
    </div>

    <VoterPictures v-if="pictures" :pictures="pictures" />
  </div>
</template>

<script>
  import dynamicFields from '@shared/helpers/dynamic-fields'
  import VoterPictures from "./VoterPictures";
  import { mapGetters } from "vuex";
  import _upperFirst from 'lodash/upperFirst'

  export default {
    name: "ProductQuestionAnswers",
    components: {
      VoterPictures
    },
    props: {
      answer: { required: true },
      question: { required: true },
      pictures: {
        required: false,
        type: Array,
        default: () => {
          return []
        }
      },
      prospectFields: {
        required: false,
        type: Object,
        default: () => {
          return {}
        },
      },
      fieldsCustomization: {
        required: false,
        type: Object,
        default: () => {
          return {}
        },
      },
      gBrandName: { required: false, type: String, default: '' }
    },
    computed: {
      ...mapGetters([
        'currentLexicon',
        'currentVoter',
      ]),
      upperFirstRecommandAnswer() {
        return _upperFirst(this.$i18n.t( this.answer.productQuestionAnswer.recommandQuestionAnswer ? 'shared.vocabulary.yes' : 'shared.vocabulary.no' ))
      },
      advancedContent() {
        let advancedContent = dynamicFields(
          this.question.content,
          this.question.subtype === 'NpsQuestion',
          this.prospectFields,
          this.fieldsCustomization,
          this.currentLexicon,
          this.gBrandName,
          this.currentVoter?.placeName,
          this.currentVoter
        )
        return advancedContent.replace("[[PRODUCT]]", this.answer.product.translatedName)
      },
    }
  }
</script>

<style lang="stylus" scoped>
  .question-tree
    .question-filled-level-0
      margin-top: 20px

  .theme--light.v-stepper { background-color:transparent; }
</style>
