var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{staticClass:"pr-3 pb-3 w-stats-widget",attrs:{"cols":12,"lg":_vm.cols,"md":_vm.cols < 6 ? 6 : 12}},[_c(_vm.linkTo ? 'router-link' : 'div',{tag:"router-link",staticClass:"widget-link",attrs:{"to":_vm.linkTo}},[_c('v-card',{class:[
        'v-card',
        'd-flex',
        'flex-column',
        _vm.linkTo && !_vm.border ? 'clickable' : '',
      ],style:({ height: _vm.height, minHeight: _vm.minHeight, maxHeight: _vm.maxHeight }),attrs:{"id":_vm.widgetId}},[(_vm.displayHeader)?_c('div',{staticClass:"pa-3 w-stats-widget-header d-flex align-center",attrs:{"id":_vm.widgetHeaderId}},[_c('span',{staticClass:"la fs-14 w-stats-widget-header-picture"},[(_vm.$slots.headerPicture || _vm.headerEmoji || _vm.headerIcon)?_c('v-chip',{staticClass:"header-picture ma-2 bg-bodygrey",attrs:{"label":""}},[_vm._t("headerPicture",function(){return [(_vm.headerEmoji)?_c('span',[_vm._v("\n                "+_vm._s(_vm.headerEmoji)+"\n              ")]):_vm._e(),_vm._v(" "),(_vm.headerIcon)?_c('v-icon',[_vm._v("\n                "+_vm._s(_vm.headerIcon)+"\n              ")]):_vm._e()]})],2):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"fs-14 w-stats-widget-title c-primary"},[_vm._t("title",function(){return [_vm._v("\n              "+_vm._s(_vm.title)+"\n            ")]})],2),_vm._v(" "),_c('span',{staticClass:"fs-10 lh-10 pt-1 c-grey"},[_vm._t("subtitle",function(){return [_vm._v("\n              "+_vm._s(_vm.subtitle)+"\n            ")]})],2)]),_vm._v(" "),_c('div',{staticClass:"flex-grow-1"}),_vm._v(" "),(_vm.linkTo)?_c('v-icon',{staticClass:"c-accent f-13"},[_vm._v("\n          mdi-chevron-right\n        ")]):_vm._e(),_vm._v(" "),_vm._t("help-tooltip",function(){return [(_vm.help)?_c('v-tooltip',{attrs:{"top":"","transition":"none"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"f-16 c-oslo-gray"},on),[_vm._v("\n                mdi-information\n              ")])]}}],null,false,1068688510)},[_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.help)+"\n            ")])]):_vm._e()]}),_vm._v(" "),_c('div',{staticClass:"lh-14"},[_vm._t("options")],2)],2):_vm._e(),_vm._v(" "),_c('div',{class:[
          'widget-content-container',
          'align-self-center',
          'd-flex',
          'flex-column',
          'align-center',
          'overflow-auto',
          _vm.justifyCenter ? 'justify-center' : '',
        ],style:({
          borderLeft: _vm.border ? `8px solid ${_vm.border}` : false,
          padding: _vm.contentPadding ? _vm.contentPadding : false,
        }),on:{"scroll":_vm.onContentScroll}},[(!_vm.empty && !_vm.loading)?_c('div',{style:(`width: ${_vm.contentWidth}`)},[_vm._t("content")],2):(_vm.loading)?_c('div',{staticClass:"f-14 full-width pa-3",style:(_vm.loadingHeight ? `height:${_vm.loadingHeight}` : null)},[_c('v-skeleton-loader',{attrs:{"type":"paragraph"}})],1):(_vm.empty)?_c('div',{staticClass:"ma-auto f-13 pa-3 c-textgrey"},[_c('div',[_vm._v(_vm._s(_vm.noDataLabel))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"f-12 c-grey lh-30 bg-palegrey px-3 d-flex",attrs:{"id":_vm.widgetFooterId}},[_c('div',[_vm._t("footer-left",function(){return [(_vm.footerSpacer)?_c('div',[_vm._v(" ")]):_vm._e()]})],2),_vm._v(" "),_c('div',{staticClass:"flex-grow-1"}),_vm._v(" "),_c('div',{staticClass:"lh-30"},[_vm._t("footer-right",function(){return [(_vm.footerSpacer)?_c('div',[_vm._v(" ")]):_vm._e()]})],2)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }